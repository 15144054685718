// eslint-disable-next-line import/prefer-default-export
export const timezones = [
  {
    timezone: "America/Los_Angeles|pt",
    name: "Pacific Time (PST/PDT)"
  },
  {
    timezone: "America/Chicago|ct",
    name: "Central Time (CST/CDT)"
  },
  {
    timezone: "America/Denver|mt",
    name: "Mountain Time (MST/MDT)"
  },
  {
    timezone: "Etc/GMT-12",
    name: "Etc/GMT-12"
  },
  {
    timezone: "Etc/GMT-11",
    name: "Etc/GMT-11"
  },
  {
    timezone: "Pacific/Midway",
    name: "Pacific/Midway"
  },
  {
    timezone: "America/Adak",
    name: "America/Adak"
  },
  {
    timezone: "America/Anchorage",
    name: "America/Anchorage"
  },
  {
    timezone: "America/Anchorage|akt",
    name: "Alaska Time"
  },
  {
    timezone: "Pacific/Gambier",
    name: "Pacific/Gambier"
  },
  {
    timezone: "America/Dawson_Creek",
    name: "America/Dawson_Creek"
  },
  {
    timezone: "America/Ensenada",
    name: "America/Ensenada"
  },
  {
    timezone: "America/Los_Angeles",
    name: "America/Los_Angeles"
  },
  {
    timezone: "America/Chihuahua",
    name: "America/Chihuahua"
  },
  {
    timezone: "America/Denver",
    name: "America/Denver"
  },
  {
    timezone: "America/Phoenix",
    name: "America/Phoenix"
  },
  {
    timezone: "America/Belize",
    name: "America/Belize"
  },
  {
    timezone: "America/Cancun",
    name: "America/Cancun"
  },
  {
    timezone: "America/Chicago",
    name: "America/Chicago"
  },
  {
    timezone: "Chile/EasterIsland",
    name: "Chile/EasterIsland"
  },
  {
    timezone: "America/Bogota",
    name: "America/Bogota"
  },
  {
    timezone: "America/Havana",
    name: "America/Havana"
  },
  {
    timezone: "America/New_York",
    name: "America/New_York"
  },
  {
    timezone: "America/New_York|et",
    name: "Eastern Time"
  },
  {
    timezone: "America/Caracas",
    name: "America/Caracas"
  },
  {
    timezone: "America/Campo_Grande",
    name: "America/Campo_Grande"
  },
  {
    timezone: "America/Glace_Bay",
    name: "America/Glace_Bay"
  },
  {
    timezone: "America/Goose_Bay",
    name: "America/Goose_Bay"
  },
  {
    timezone: "America/Santiago",
    name: "America/Santiago"
  },
  {
    timezone: "America/La_Paz",
    name: "America/La_Paz"
  },
  {
    timezone: "America/Argentina/Buenos_Aires",
    name: "America/Argentina/Buenos_Aires"
  },
  {
    timezone: "America/Montevideo",
    name: "America/Montevideo"
  },
  {
    timezone: "America/Araguaina",
    name: "America/Araguaina"
  },
  {
    timezone: "America/Godthab",
    name: "America/Godthab"
  },
  {
    timezone: "America/Miquelon",
    name: "America/Miquelon"
  },
  {
    timezone: "America/Sao_Paulo",
    name: "America/Sao_Paulo"
  },
  {
    timezone: "America/St_Johns",
    name: "America/St_Johns"
  },
  {
    timezone: "America/Noronha",
    name: "America/Noronha"
  },
  {
    timezone: "Atlantic/Cape_Verde",
    name: "Atlantic/Cape_Verde"
  },
  {
    timezone: "Europe/Belfast",
    name: "Europe/Belfast"
  },
  {
    timezone: "Africa/Abidjan",
    name: "Africa/Abidjan"
  },
  {
    timezone: "Europe/Dublin",
    name: "Europe/Dublin"
  },
  {
    timezone: "Europe/Lisbon",
    name: "Europe/Lisbon"
  },
  {
    timezone: "Europe/London",
    name: "Europe/London"
  },
  {
    timezone: "UTC",
    name: "UTC"
  },
  {
    timezone: "Africa/Algiers",
    name: "Africa/Algiers"
  },
  {
    timezone: "Africa/Windhoek",
    name: "Africa/Windhoek"
  },
  {
    timezone: "Atlantic/Azores",
    name: "Atlantic/Azores"
  },
  {
    timezone: "Atlantic/Stanley",
    name: "Atlantic/Stanley"
  },
  {
    timezone: "Europe/Amsterdam",
    name: "Europe/Amsterdam"
  },
  {
    timezone: "Europe/Belgrade",
    name: "Europe/Belgrade"
  },
  {
    timezone: "Europe/Brussels",
    name: "Europe/Brussels"
  },
  {
    timezone: "Africa/Cairo",
    name: "Africa/Cairo"
  },
  {
    timezone: "Africa/Blantyre",
    name: "Africa/Blantyre"
  },
  {
    timezone: "Asia/Beirut",
    name: "Asia/Beirut"
  },
  {
    timezone: "Asia/Damascus",
    name: "Asia/Damascus"
  },
  {
    timezone: "Asia/Gaza",
    name: "Asia/Gaza"
  },
  {
    timezone: "Asia/Jerusalem",
    name: "Asia/Jerusalem"
  },
  {
    timezone: "Africa/Addis_Ababa",
    name: "Africa/Addis_Ababa"
  },
  {
    timezone: "Asia/Riyadh89",
    name: "Asia/Riyadh89"
  },
  {
    timezone: "Europe/Minsk",
    name: "Europe/Minsk"
  },
  {
    timezone: "Asia/Tehran",
    name: "Asia/Tehran"
  },
  {
    timezone: "Asia/Dubai",
    name: "Asia/Dubai"
  },
  {
    timezone: "Asia/Yerevan",
    name: "Asia/Yerevan"
  },
  {
    timezone: "Europe/Moscow",
    name: "Europe/Moscow"
  },
  {
    timezone: "Asia/Kabul",
    name: "Asia/Kabul"
  },
  {
    timezone: "Asia/Tashkent",
    name: "Asia/Tashkent"
  },
  {
    timezone: "Asia/Karachi",
    name: "Asia/Karachi"
  },
  {
    timezone: "Asia/Kolkata",
    name: "Asia/Kolkata"
  },
  {
    timezone: "Asia/Katmandu",
    name: "Asia/Katmandu"
  },
  {
    timezone: "Asia/Dhaka",
    name: "Asia/Dhaka"
  },
  {
    timezone: "Asia/Yekaterinburg",
    name: "Asia/Yekaterinburg"
  },
  {
    timezone: "Asia/Rangoon",
    name: "Asia/Rangoon"
  },
  {
    timezone: "Asia/Bangkok",
    name: "Asia/Bangkok"
  },
  {
    timezone: "Asia/Novosibirsk",
    name: "Asia/Novosibirsk"
  },
  {
    timezone: "Etc/GMT+8",
    name: "Etc/GMT+8"
  },
  {
    timezone: "Asia/Hong_Kong",
    name: "Asia/Hong_Kong"
  },
  {
    timezone: "Asia/Krasnoyarsk",
    name: "Asia/Krasnoyarsk"
  },
  {
    timezone: "Australia/Perth",
    name: "Australia/Perth"
  },
  {
    timezone: "Australia/Eucla",
    name: "Australia/Eucla"
  },
  {
    timezone: "Asia/Irkutsk",
    name: "Asia/Irkutsk"
  },
  {
    timezone: "Asia/Seoul",
    name: "Asia/Seoul"
  },
  {
    timezone: "Asia/Tokyo",
    name: "Asia/Tokyo"
  },
  {
    timezone: "Australia/Adelaide",
    name: "Australia/Adelaide"
  },
  {
    timezone: "Australia/Darwin",
    name: "Australia/Darwin"
  },
  {
    timezone: "Pacific/Marquesas",
    name: "Pacific/Marquesas"
  },
  {
    timezone: "Etc/GMT+10",
    name: "Etc/GMT+10"
  },
  {
    timezone: "Australia/Brisbane",
    name: "Australia/Brisbane"
  },
  {
    timezone: "Australia/Hobart",
    name: "Australia/Hobart"
  },
  {
    timezone: "Asia/Yakutsk",
    name: "Asia/Yakutsk"
  },
  {
    timezone: "Australia/Lord_Howe",
    name: "Australia/Lord_Howe"
  },
  {
    timezone: "Asia/Vladivostok",
    name: "Asia/Vladivostok"
  },
  {
    timezone: "Pacific/Norfolk",
    name: "Pacific/Norfolk"
  },
  {
    timezone: "Etc/GMT+12",
    name: "Etc/GMT+12"
  },
  {
    timezone: "Asia/Anadyr",
    name: "Asia/Anadyr"
  },
  {
    timezone: "Asia/Magadan",
    name: "Asia/Magadan"
  },
  {
    timezone: "Pacific/Auckland",
    name: "Pacific/Auckland"
  },
  {
    timezone: "Pacific/Chatham",
    name: "Pacific/Chatham"
  },
  {
    timezone: "Pacific/Tongatapu",
    name: "Pacific/Tongatapu"
  },
  {
    timezone: "Pacific/Kiritimati",
    name: "Pacific/Kiritimati"
  }
]